import React, {useContext, useEffect, useState} from 'react'
import globalStates from "../context/globalStates";


const Redirect = () => {
  const [userAgent, setUserAgent] = useState('')
  const { innerTexts } = useContext(globalStates);
  const [counter, setCounter] = useState(5);
  useEffect(() => {
    let android = /Android/i.test(navigator.userAgent)
    let ios = /iPhone|iPad|iPod/i.test(navigator.userAgent)
    let windows = /IEMobile/i.test(navigator.userAgent)

    if(android) {
      setUserAgent('Android')
    } else if(ios) {
      setUserAgent('iOS')
    } else if(windows) {
      setUserAgent('Windows')
    } else {
      setUserAgent('Desktop')
    }
  }, [])

  const delayedRedirect = () => {
    if(userAgent === 'Android' && innerTexts.companyAppLinkAndroid) {
      window.location.replace(innerTexts.companyAppLinkAndroid)
    } else if(userAgent === 'iOS' && innerTexts.companyAppLinkIos) {
      window.location.replace(innerTexts.companyAppLinkIos)
    }
  }

  useEffect(() => {
    if(userAgent === 'Android' || userAgent === 'iOS') {
      if(counter > 0) {
        setTimeout(() => {
          console.log(counter);
          setCounter(t => t - 1)
        }, 1000);
      } else {
        delayedRedirect()
      }
    } else if(userAgent === 'Desktop') {
      setCounter('desktop')
    }
  }, [counter, userAgent])


  return (
    <div className="container" style={{ backgroundColor: "#fff", textAlign: "center", marginTop: 50 }}>
      {(!innerTexts.companyAppLinkAndroid && userAgent === 'Android') && <p style={{ textAlign: "center" }}>
        Android uygulama linkleri geldiğinde, ilgili sayfaya yönlendirilecektir.
      </p>
      }
      {(!innerTexts.companyAppLinkIos && userAgent === 'iOS') && <p style={{ textAlign: "center" }}>
        iOS uygulama linkleri geldiğinde, ilgili sayfaya yönlendirilecektir.
      </p>
      }
      {(innerTexts.companyAppLinkIos && userAgent === 'iOS') && <p style={{ textAlign: "center" }}>
        App Store uygulama indirme sayfasına yönlendiriliyorsunuz...
      </p>
      }

      {(innerTexts.companyAppLinkAndroid && userAgent === 'Android') && <p style={{ textAlign: "center" }}>
        Google Play uygulama indirme sayfasına yönlendiriliyorsunuz...
      </p>
      }

      <p style={{ textAlign: "center" }}>
        Cihazınız: <b>{userAgent}</b>
      </p>
      {counter !== 'desktop' && <p style={{textAlign: "center"}}>
        {counter} saniye içinde yönlendirileceksiniz...
      </p>}
      {userAgent === 'Windows' || userAgent === 'Desktop' &&
        <h5 style={{ textAlign: "center" }}>Lütfen mobil uygulamayı yüklemek için <b>mobil</b> cihazınızdan giriş yapınız.</h5>
      }
    </div>
  )
}

export default Redirect;
