import React, { useContext, useEffect, useState, useRef } from "react";
import globalStates from "../../../../context/globalStates";
import useRequest from "../../../../hooks/useRequest";
import moment from "moment";
import { customFetch } from "../../../../hooks/customFetch";
import "moment/locale/tr";

const StepIdentity = (props) => {
  console.log("props.wait", props.wait);
  const { innerTexts } = useContext(globalStates);
  const [timeOff, setTimeOff] = useState("");

  let videoCallBtnRef = useRef();

  useEffect(() => {
    let dateString;
    let timeOfDay;
    let weekDay;

    customFetch({
      params: "islem=time_information",
      method: "GET",
      onSuccess: (response) => {
        if (
          response.Time !== undefined &&
          response.Time !== null &&
          response.Time !== ""
        ) {
          dateString = response.Time;
          // dateString = '2024-03-18T16:30:21.9706713+03:00' // for test purposes
          timeOfDay = moment(dateString).format("HH:mm");
          weekDay = moment(dateString).format("ddd");

          if (
            innerTexts.aksisWeekdaysTimes !== undefined &&
            innerTexts.aksisWeekdaysTimes !== null &&
            innerTexts.aksisWeekdaysTimes !== ""
          ) {
            let dayResult = innerTexts.aksisWeekdaysTimes.find((item) => {
              if (item.day === weekDay && item.active) {
                return item;
              }
            });

            if (dayResult && dayResult.active) {
              if (!dayResult.start2 || !dayResult.end2) {
                if (
                  moment(timeOfDay, "HH:mm").isBefore(
                    moment(dayResult.start, "HH:mm")
                  ) ||
                  moment(timeOfDay, "HH:mm").isAfter(
                    moment(dayResult.end, "HH:mm")
                  )
                ) {
                  innerTexts.id !== "INFOYATIRIM"
                    ? setTimeOff(
                        `Görüntülü görüşme servisi ${moment(dateString).format(
                          "dddd"
                        )} günü için ${dayResult.start} - ${
                          dayResult.end
                        } saatleri arasında aktiftir. Lütfen çalışma saatlerinde tekrar deneyiniz.`
                      )
                    : setTimeOff(
                        `Görüntülü görüşme servisi calışma saatlerinde hizmet vermektedir. Müşteri Temsilciniz çalışma saatleri içinde size ulaşıp işlemi tamamlamanız yardımcı olacaktır.`
                      );
                } else {
                  setTimeOff("");
                }
              } else {
                if (
                  moment(timeOfDay, "HH:mm").isBefore(
                    moment(dayResult.start, "HH:mm")
                  ) ||
                  moment(timeOfDay, "HH:mm").isAfter(
                    moment(dayResult.end2, "HH:mm")
                  ) ||
                  (moment(timeOfDay, "HH:mm").isAfter(
                    moment(dayResult.end, "HH:mm")
                  ) &&
                    moment(timeOfDay, "HH:mm").isBefore(
                      moment(dayResult.start2, "HH:mm")
                    ))
                ) {
                  innerTexts.id !== "INFOYATIRIM"
                    ? setTimeOff(
                        `Görüntülü görüşme servisi ${moment(dateString).format(
                          "dddd"
                        )} günü için ${dayResult.start} - ${dayResult.end} ve ${
                          dayResult.start2
                        } - ${
                          dayResult.end2
                        } saatleri arasında aktiftir. Lütfen çalışma saatlerinde tekrar deneyiniz.`
                      )
                    : setTimeOff(
                        `Görüntülü görüşme servisi calışma saatlerinde hizmet vermektedir. Müşteri Temsilciniz çalışma saatleri içinde size ulaşıp işlemi tamamlamanız yardımcı olacaktır.`
                      );
                } else {
                  setTimeOff("");
                }
              }
            } else {
              setTimeOff(
                `Görüntülü görüşme servisi ${moment(dateString).format(
                  "dddd"
                )} günü için aktif değildir.`
              );
            }
          }
        }
      },
    });
  }, []);

  console.log("timeOff", timeOff);
  const timeOffAlert = () => {
    alert(timeOff);
  };
  return (
    <div className="row" style={{ marginTop: "2rem" }}>
      {props.aksisStatusText && (
        <div className="col-md-12">
          <div className="alert alert-warning" role="alert">
            {props.aksisStatusText}
          </div>
        </div>
      )}
      {innerTexts.dijitalKurye && (
        <div className="col-md-4">
          <div
            className={["conractsCard", "card"].join(" ")}
            style={{
              border: "1px solid #eee;",
              boxShadow:
                timeOff || props.wait
                  ? "none"
                  : "0px 0px 13px -4px rgba(0,0,0,0.35) !important;",
              cursor: timeOff || props.wait ? "not-allowed" : "pointer",
              touchAction: timeOff || props.wait ? "none" : "auto",
            }}
          >
            <div
              style={{
                opacity: timeOff ? 0.5 : 1,
                textAlign: "center",
                pointerEvents: timeOff || props.wait ? "none" : "all",
              }}
            >
              <div
                onClick={() => {
                  props.setIsDijitalKurye(true);
                }}
                className="card-body"
                style={{ textAlign: "center" }}
              >
                <img
                  style={{ width: "250px", height: "125px" }}
                  src={require("../../../../assets/dijitalKurye.png")}
                />
                <p style={{ marginTop: "2rem" }}>
                  <p style={{ marginTop: "25px" }}>
                    Dijital Kurye İle Kimlik Doğrulama
                  </p>
                  <p>
                    <span style={{ color: "#949494" }}>
                      Kimlik doğrulama işleminizi kurye ile tamamlamak için
                      tıklayınız. Bu aşamada size kurye randevusu
                      oluşturabilmeniz için bilgi SMS ‘i yönlendirilecek olup,
                      kimlik teyidiniz ve sözleşme imzalarınız belirlediğiniz
                      randevu saatinde kuryemiz tarafından tamamlanacaktır.
                    </span>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={innerTexts.dijitalKurye ? "col-md-4" : "col-md-6"}>
        <div
          className={["conractsCard", "card"].join(" ")}
          style={{
            border: "1px solid #eee;",
            boxShadow:
              timeOff || props.wait
                ? "none"
                : "0px 0px 13px -4px rgba(0,0,0,0.35) !important;",
            cursor: timeOff || props.wait ? "not-allowed" : "pointer",
            touchAction: timeOff || props.wait ? "none" : "auto",
          }}
        >
          <div
            style={{
              opacity: timeOff ? 0.5 : 1,
              textAlign: "center",
              pointerEvents: timeOff || props.wait ? "none" : "all",
            }}
          >
            <div
              ref={videoCallBtnRef}
              onClick={() => {
                if (!timeOff) {
                  if (!props.wait) {
                    props.startVideo();
                    if (videoCallBtnRef.current) {
                      videoCallBtnRef.current.setAttribute(
                        "disabled",
                        "disabled"
                      );
                    }
                  } else {
                    alert(
                      "Yeni görüntülü görüşme başlatabilmek için lütfen sürenin tamamlanmasını bekleyin."
                    );
                  }
                } else {
                  timeOffAlert();
                }
              }}
              className="card-body"
              style={{ textAlign: "center" }}
            >
              <img
                style={{ width: "125px", height: "125px" }}
                src={require("../../../../assets/videoCall.png")}
              />
              {!timeOff && (
                <p style={{ marginTop: "25px" }}>
                  {timeOff ? "" : "Görüntülü Görüşme ile Kimlik Doğrulama"}
                </p>
              )}
              <div style={{ marginTop: "2rem" }}>
                <div style={{ color: "#000" }}>
                  {timeOff || (
                    <p>
                      Kimlik doğrulama işlemini görüntülü{" "}
                      <span
                        style={
                          innerTexts.id === "MEKSA"
                            ? {
                                fontWeight: 700,
                                fontSize: "1.5rem",
                                color: "#1d72b8 ",
                              }
                            : {}
                        }
                      >
                        görüşme ile başlatmak için lütfen tıklayınız.
                      </span>{" "}
                      Telefonunuza gelecek olan talimatları izleyerek görüntülü
                      doğrulama işlemine başlayabilirsiniz.
                    </p>
                  )}
                  {innerTexts.id === "GALATA" && (
                    <h4 style={{ color: "red" }}>
                      *Sadece Çipli Kimlik Kartı ile devam edilmelidir
                    </h4>
                  )}
                  {innerTexts.id === "OSMANLIYATIRIM" && (
                    <h4 style={{ textDecoration: "underline" }}>
                      <b>GÖRÜNTÜLÜ GÖRÜŞME İÇİN TIKLAYINIZ</b>
                    </h4>
                  )}

                  {innerTexts.id === "IKON" && (
                    <strong>
                      Uygulamayı kullanırken herhangi bir sorun ile
                      karşılaşmanız halinde{" "}
                      <a
                        href="https://hesapac.ikonmenkul.com.tr/"
                        target="_blank"
                      >
                        https://hesapac.ikonmenkul.com.tr/
                      </a>{" "}
                      adresine giderek işlemlerinize kaldığınız yerden devam
                      edebilir veya{" "}
                      <a href="tel:0212 709 99 99">0212 709 99 99</a> numaralı
                      telefondan destek alabilirsiniz.
                    </strong>
                  )}
                </div>
              </div>
              <br />

              <strong>Görüntülü görüşme çalışma saatleri:</strong>
              {innerTexts.id === "IKON" && (
                <p
                  style={{
                    marginTop: "1rem",
                    fontWeight: 500,
                    color: "#0063af",
                  }}
                >
                  Hafta içi 5 gün 24 saat hesap başvurusu yapılabilmektedir.
                  Görüntülü görüşmenizi bu süre içinde dilediğiniz saatte
                  yapabilirsiniz.
                </p>
              )}
              <ul
                style={{ listStyleType: "none", padding: 0, marginTop: "1rem" }}
              >
                {!innerTexts.aksisStandardTimeEnabled ? (
                  innerTexts.aksisWeekdaysTimes.map((item, i) => {
                    if (item.active) {
                      return (
                        <li key={i}>
                          {innerTexts.id !== "OSMANLIYATIRIM"
                            ? moment().day(i).format("dddd")
                            : moment()
                                .day(i + 1)
                                .format("dddd")}{" "}
                          {item.start ?? ""} {item.start ? "-" : ""}{" "}
                          {item.end ?? ""}{" "}
                          {item.start2 && item.end2 ? "ve" : null}{" "}
                          {item.start2 ?? ""} {item.start2 ? "-" : ""}{" "}
                          {item.end2 ?? ""}{" "}
                          {item.start || item.end
                            ? "saatleri arasında"
                            : "tam gün aktiftir"}{" "}
                        </li>
                      );
                    }
                  })
                ) : (
                  <h5>
                    Müşteri Temsilcilerimizle hafta içi{" "}
                    {innerTexts.aksisStandardTime[0].start}-
                    {innerTexts.aksisStandardTime[0].end} arasında Görüntülü
                    Görüşme gerçekleştirebilirsiniz.{" "}
                  </h5>
                )}
              </ul>

              <input style={{ display: "none" }} />
            </div>
          </div>
        </div>
      </div>
      {/* second button (contracts card) */}
      {innerTexts.id === "STRATEJI" ||
      innerTexts.id === "INTEGRALYATIRIM" ||
      innerTexts.id === "AHLATCI" ||
      innerTexts.id === "NOORCM" ||
      innerTexts.id === "MEKSA" ||
      innerTexts.id === "IKON" ||
      innerTexts.id === "ACAR" ? null : (
        <div className={innerTexts.dijitalKurye ? "col-md-4" : "col-md-6"}>
          <div
            className={["conractsCard", "card"].join(" ")}
            style={{
              border: "1px solid #eee;",
              boxShadow:
                timeOff || props.wait
                  ? "none"
                  : "0px 0px 13px -4px rgba(0,0,0,0.35) !important;",
              cursor: "pointer",
              opacity: props.canUploadDocuments ? 1 : 0.3,
              minHeight: "50vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                textAlign: "center",
                cursor: props.canUploadDocuments ? "pointer" : "not-allowed",
              }}
            >
              <div
                onClick={() => {
                  if (props.canUploadDocuments) {
                    props.setContract(true);
                    if (innerTexts.id === "A1CAPITAL") {
                      props.handleCustomAgreementPopup();
                    }
                  } else {
                    alert(
                      "Evrak yükleme yoluyla hesap açabilmeniz için 'Görüntülü Görüşme' sürecinizin tamamlanması ya da sonlanması gerekmektedir. Lütfen görüntülü görüşme sürecini tamamlayınız."
                    );
                  }
                }}
                className="card-body"
                style={{ textAlign: "center" }}
              >
                <img
                  style={{ width: "100px", height: "100px" }}
                  src={"/images/photonull.png"}
                />
                <p style={{ marginTop: "25px" }}>
                  Evrak Yükleme ile Kimlik Doğrulama
                </p>
                <p>
                  <span style={{ color: "#949494" }}>
                    {props.canUploadDocuments
                      ? "Kimlik doğrulama işlemini evrak yükleyerek tamamlamak için lütfen tıklayınız. Bu aşamada sizden istenecek olan evrakları eksiksiz olarak sisteme yüklemeniz gerekmektedir."
                      : "Evrak yükleme yoluyla hesap açabilmeniz için 'Görüntülü Görüşme' sürecinizin tamamlanması ya da sonlanması gerekmektedir. Lütfen görüntülü görüşme sürecini tamamlayınız."}
                  </span>
                </p>
                {innerTexts.id === "GALATA" && (
                  <h4 style={{ color: "red" }}>
                    *Sadece Çipli Kimlik Kartı ile devam edilmelidir
                  </h4>
                )}
                {innerTexts.id === "OSMANLIYATIRIM" && (
                  <h4 style={{ color: "#949494" }}>
                    Evrak yükleme adımı sonunda sözleşme aslını{" "}
                    <span style={{ textDecoration: "underline" }}>
                      <b>kargo ile göndermeniz</b>
                    </span>{" "}
                    gerekmektedir.
                  </h4>
                )}
                {innerTexts.id === "INFOYATIRIM" && (
                  <p style={{ marginTop: "2rem", color: "#949494" }}>
                    Evrakla tamamlanan süreçlerde sözleşme çıktılarını imzalayıp
                    tarafımıza göndermeniz gerekmektedir.
                  </p>
                )}
                <input style={{ display: "none" }} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepIdentity;
