import React, { useEffect } from "react";
import Form from "../components/widgets/form";
const Home = (props) => {
  useEffect(() => {
    if (document.getElementById("loader").style.display !== "none") {
      setTimeout(() => {
        document.getElementById("loader").style.display = "none";
      }, 2000);
    }
  }, []);
  return (
    <div style={{ position: "relative" }}>
      <div className="container-fluid bg">
        <div className="loader-container" id="loader">
          <div className="holder">
            <div className="la-line-scale la-2x">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-5">

              <div className="text_box">
                Görüntülü Görüşme ile
                <strong>Hemen Hesap Aç!</strong>
              </div>

              <div className="campaign-wrapper">
                <a
                  href="https://ikonmenkul.com.tr/ucretsiz-veri-kampanyasi"
                  target="_blank"
                >
                  <img
                    src="/images/kampanya/Hesap_Ac_Banner.png"
                    alt="kampanya"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-7">
              <div className="form_box">
                <Form />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid work_box">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-md-4">
              <span>
                <img src="images/giris.svg" alt="" />
                <strong>Giriş Yapınız</strong>
              </span>
              <p>
                Uygunluk anketimizi doldurarak, kendinize uygun ürün ve
                hizmetleri seçebilirsiniz.
              </p>
            </div>

            <div className="col-sm-4 col-md-4">
              <span>
                <img src="/images/sozlesme.svg" alt="" />
                <strong>Sözleşme Onayı</strong>
              </span>
              <p>
                Görüntülü görüşme ile hemen hesap açıp, işlem yapmaya
                başlayabilirsiniz.
              </p>
            </div>

            <div className="col-sm-4 col-md-4">
              <span>
                <img src="/images/onay.svg" alt="" />
                <strong>Hesabınızı Kullanmaya Başlayın</strong>
              </span>
              <p>
                Adresinize göndereceğimiz belgeleri imzaladıktan hemen sonra
                işlem yapmaya başlayabilirsiniz.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="campaign-banner-desktop">
        <a
          href="https://ikonmenkul.com.tr/ucretsiz-veri-kampanyasi"
          target="_blank"
        >
          <img src="/images/kampanya/Kampanya_Web.png" alt="kampanya" />
        </a>
      </div> */}

      {/* <div className="campaign-banner-mobile">
        <a
          href="https://ikonmenkul.com.tr/ucretsiz-veri-kampanyasi"
          target="_blank"
        >
          <img src="/images/kampanya/Kampanya_Mobil.png" alt="kampanya" />
        </a>
      </div> */}
    </div>
  );
};
export default Home;
