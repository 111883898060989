import React, { useState } from 'react';
import Context from './globalStates';
import List from "../sources/variables/companies.json";
import { customFetch } from '../hooks/customFetch';


const ProviderContext = props => {

  const [token, setToken] = useState(false);
    const slugify = (text) => {
      return text.toString()
    }

    // remove turkish characters from a string
    const removeTurkishChars = (text) => {
      const turkishChars = {
        'ç': 'c',
        'ğ': 'g',
        'ı': 'i',
        'ö': 'o',
        'ş': 's',
        'ü': 'u',
        'Ç': 'C',
        'Ğ': 'G',
        'İ': 'I',
        'Ö': 'O',
        'Ş': 'S',
        'Ü': 'U'
      }
      let newText = text
      for (let key in turkishChars) {
        newText = newText.replace(key, turkishChars[key])
      }
      return newText
    }

    const InnerTexts =  () => {

      const locales = List.filter(
        (x) => x.id === process.env.REACT_APP_BUILDTYPE
      );

      return locales[0] || {};
    };

    return (
      <Context.Provider
        value={{
          token: token,
          setToken: setToken,
          slugify: slugify,
          innerTexts: InnerTexts(),
        }}
      >
        {props.children}
      </Context.Provider>
    );
}
export default ProviderContext
