import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useRequest from "../../../../../hooks/useRequest";
import globalStates from "../../../../../context/globalStates";
import { useCookies } from "react-cookie";
import { decryptData } from "../../../../../helper/helper";
const EpisodeTwo = (props) => {
  const [prefer, setPrefer] = useState("E");
  const { startFetch, response, loading } = useRequest();
  const refInput = useRef([]);
  const { innerTexts } = useContext(globalStates);
  const [cookies] = useCookies(["TC"]);

  useEffect(() => {
    if (response) {
      if (response.errNo === 0) {
        if (response.bilgiler) {
          var data = response.bilgiler;
          if (data.iletisim_tercihi === "") {
            setTimeout(() => {
              handleAnswer(1);
              setPrefer("E");
            }, 100);
            return;
          }
          setPrefer(data.iletisim_tercihi);
        } else {
          props.nextStep();
        }
      }
    }
  }, [response]);

  useEffect(() => {
    startFetch("islem=yatirimci_bilgileri_getir&step=3", "GET");
  }, []);

  const handleAnswer = (key) => {
    try {
      if (key === 0) {
        refInput.current[0].className = "card active-card";
        refInput.current[1].className = "card none";
        setPrefer("P");
      } else {
        refInput.current[1].className = "card active-card";
        refInput.current[0].className = "card none";
        setPrefer("E");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveAnswer = () => {
    var errorElement = window.jQuery("html");
    var scrollPosition = errorElement.offset().top - 25;
    window.jQuery("html, body").animate({ scrollTop: scrollPosition }, 500);

    var params = "";

    var model = {
      islem: "ekstre_iletisim_tercihleri",
      step: 3,
      iletisim_tercihi: prefer,
      tc_no: decryptData(cookies.TC),
    };

    for (var I in model) {
      params += "" + I + "=" + model[I] + "&";
    }

    startFetch(params, "POST");
  };

  if (props.currentStep !== 2) {
    return <div></div>;
  }

  return (
    <Fragment>
      <div style={{ textAlign: "center" }}>
        <h2>Ekstre ve İletişim Kanalı</h2>
        <div style={{ textAlign: "left" }} className="row">
          <div className="col-md-12">
            <div
              ref={(r) => {
                refInput.current[1] = r;
              }}
              onClick={(e) => {
                handleAnswer(1);
              }}
              style={{ cursor: "pointer", width: "100%" }}
              className={
                prefer === "E"
                  ? "card active-card"
                  : prefer === "P"
                  ? "card none"
                  : "card"
              }
            >
              <div style={{ cursor: "pointer" }} className="card-body">
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="form-check"
                >
                  <label className="form-check-label" htmlFor="POSTA">
                    E-POSTA
                  </label>
                  {innerTexts.id === "OSMANLIYATIRIM" && (
                    <svg
                      style={{ marginLeft: "15px" }}
                      height="18px"
                      width="18px"
                      viewBox="0 0 18 18"
                      preserveAspectRatio="xMinYMin meet"
                    >
                      <g>
                        <path
                          style={{ fill: "#030104" }}
                          d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27
                c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0
                L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                        />
                      </g>
                    </svg>
                  )}
                </div>
              </div>
            </div>
            {innerTexts.id !== "OSMANLIYATIRIM" &&
              innerTexts.id !== "MEKSA" &&
              innerTexts.id !== "ALLBATROSS" && (
                <div
                  ref={(r) => {
                    refInput.current[0] = r;
                  }}
                  onClick={(e) => {
                    handleAnswer(0);
                  }}
                  style={{ cursor: "pointer", width: "100%" }}
                  className={
                    prefer === "P"
                      ? "card active-card"
                      : prefer === "E"
                      ? "card none"
                      : "card"
                  }
                >
                  <div style={{ cursor: "pointer" }} className="card-body">
                    <div style={{ cursor: "pointer" }} className="form-check">
                      <label className="form-check-label" htmlFor="POSTA">
                        POSTA (Fiziksel İletim)
                      </label>
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div style={{ marginTop: "5%" }} className="row">
            <div className="col-md-6">
              <button
                onClick={() => {
                  props.previousStep();
                }}
                className="btn  btn-secondr btn-lg btn-block"
                disabled={loading}
                style={{
                  filter: loading ? "brightness(120%)" : "",
                  cursor: loading ? "no-drop" : "pointer",
                }}
              >
                Geri
              </button>
            </div>
            <div className="col-md-6">
              <button
                onClick={saveAnswer}
                className="btn  btn-primary btn-lg btn-block"
                disabled={loading}
                style={{
                  filter: loading ? "brightness(120%)" : "",
                  cursor: loading ? "no-drop" : "pointer",
                }}
              >
                İleri
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default EpisodeTwo;
