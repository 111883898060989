
import React, { useEffect } from 'react'    
const NoMatch = (props)=>{  

    useEffect(()=>{ 
        if(document.getElementById("loader").style.display !== "none"){
            setTimeout(() => {
                document.getElementById("loader").style.display = "none"
            }, 2000);    
        } 
        setTimeout(()=>{
            window.location.replace("/")
        }, 2000)
    }, [])

    return(
        <div  id="wrapper">
        <div className="loader-container" id="loader">
        <div className="holder">
            <div className="la-line-scale la-2x">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        </div>
        <main  id="main">
                <div className="row">
                    <div style={{textAlign:"center", marginTop:200}} className="col-md-12">
                        <h1>Sayfaya Erişiminiz Yok</h1>
                    </div>
                </div>
        </main>
    </div>
    )
      
}
export default NoMatch