import React from 'react'
import EpisodeOne from './step2/episode' 
const Step2 = (props)=>{  
if(props.currentStep !== 2){
    return (<div></div>)
}
    return (
        <div className="panel-body">
            <div className="container"> 
            <EpisodeOne handleWizard={props} handleProgress={props.handleProgress}/>
            </div>
        </div>)
}
export default Step2