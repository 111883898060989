import React, {useContext, useEffect} from 'react'
import Index from '../components/widgets/wizard'
import { useCookies } from 'react-cookie';
import TagManager from "react-gtm-module";
import {Helmet} from "react-helmet";
import globalStates from "../context/globalStates";

const Register = (props)=>{
    const [cookie, setCookie] = useCookies(["token", "step"])
    const {innerTexts} = useContext(globalStates);

    if(!cookie.token){
        window.location.replace("/404")
    }

    const sendGTMData = () => {
        if (process.env.REACT_APP_BUILDTYPE === "A1CAPITAL") {
            const url = window.location.href
            const args = {
                dataLayer: {
                    event: "conversion",
                    url: url,
                },
                dataLayerName: "GoruntuluGorusmeFormu",
            }
            TagManager.dataLayer(args);
        }
    }

    useEffect(() => {
        sendGTMData()
    }, [])

    const tagManagerScript = () => {
        const { id } = innerTexts;
        let result = ``;
        let ns = ``;
        switch (id) {
            case 'NOORCM':
                result = `gtag('event', 'conversion', {'send_to': 'AW-994801157/LBguCJ2N4-oBEIXsrdoD'});
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '287764475267424');
                            fbq('track', 'PageView');
                            `
                ns = `<img height="1" width="1" style="display:none"
                        src="https://www.facebook.com/tr?id=287764475267424&ev=PageView&noscript=1"
                        />`
                break;
        }
        if(result !== ``){
            return (
                <Helmet>
                    <script>{result}</script>
                    <noscript>{ns}</noscript>
                </Helmet>
            )
        }
    }



    return (
        <div style={{ marginBottom: "10%", backgroundColor: 'white' }}>
            {tagManagerScript()}
            <Index  {...props}/>
        </div>
    )
}
export default Register
