import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import globalStates from "../../../../../context/globalStates";
import ReactLoading from "react-loading";
import useRequest from "../../../../../hooks/useRequest";
import { decryptData } from "../../../../../helper/helper";

const AdressVerify = (props) => {
  const { innerTexts } = useContext(globalStates);
  const [cookies, setCookie] = useCookies(["token", "step", "TC"]);
  const [verified, setVerified] = useState(false);
  const [verifyCities, setVerifyCities] = useState([]);
  const [verifyRegions, setVerifyRegions] = useState([]);
  const [verifyStreets, setVerifyStreets] = useState([]);
  const [verifyMah, setVerifyMah] = useState([]);
  const [verifyBuildings, setVerifyBuildings] = useState([]);
  const [verifyFlat, setVerifyFlat] = useState([]);
  const [mah, setMah] = useState("");
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [building, setBuilding] = useState("");
  const [flat, setFlat] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const { startFetch, fetchAdress, response, loading } = useRequest();
  const { complateAddressVerify, addressAlreadyVerified } = props;
  const [addressSelectionError, setAddressSelectionError] = useState("");

  const [adress, setAdress] = useState("");

  function maskWords(text) {
    const words = text.split(" ");

    const maskedWords = words.map((word) => {
      if (word.length < 2) {
        return word + "***";
      } else {
        const firstTwoChars = word.slice(0, 2);
        return firstTwoChars + "***";
      }
    });

    const maskedText = maskedWords.join(" ");

    return maskedText;
  }

  function maskKpsWords(address) {
    const addressList = address.split(" ");

    let mahalle = addressList[0]?.slice(0, 2) + "***";
    let sokak = "";
    let ilce = addressList[addressList.length - 2]?.slice(0, 1) + "***";
    let il = addressList[addressList.length - 1]?.slice(0, 1) + "***";
    let numara = "";
    let sokakOrCadde = " CA*** ";

    for (let i = 0; i < addressList.length; i++) {
      if (addressList[i].includes("MAH.")) {
        const nextElement = addressList[i + 1];
        sokak = nextElement?.slice(0, 2) + "***";
      }
      if (addressList[i].includes("NO:")) {
        const nextElement = addressList[i + 1];
        numara = nextElement?.slice(0, 2) + "***";
      }
      if (addressList[i].includes("SK.")) {
        sokakOrCadde = " SO*** ";
      }
    }
    const modifiedAddress =
      mahalle +
      " MA*** " +
      sokak +
      sokakOrCadde +
      numara +
      " " +
      ilce +
      " " +
      il;
    return modifiedAddress;
  }

  useEffect(() => {
    innerTexts.addresVerify
      ? setAdress(maskKpsWords(props.address))
      : setAdress(maskWords(props.address));
  }, [props.address]);

  const handleSelectCity = (e) => {
    if (e.target.value === innerTexts.label_pick_city) {
      setVerifyRegions(null);
    }
    if (innerTexts.addresVerify) {
      fetchAdress(`/ilce/${e.target.value}/${decryptData(cookies?.TC)}`, "GET");
      setCity(e.target.value);
      props.setCity(
        verifyCities?.filter((x) => x.Kod == e.target.value)[0]?.Ad
      );
      return;
    }
  };

  const handleSelectRegion = (e) => {
    if (e.target.value === innerTexts.label_pick_region) {
      setVerifyMah(null);
    }
    if (innerTexts.addresVerify) {
      fetchAdress(
        `/mahalle/${e.target.value}/${decryptData(cookies?.TC)}`,
        "GET"
      );
      setRegion(e.target.value);
      props.setRegion(
        verifyRegions?.filter((x) => x.Kod == e.target.value)[0]?.Ad
      );
      return;
    }
  };

  const handleSelectMah = (e) => {
    if (e.target.value === innerTexts.label_pick_mah) {
      setVerifyStreets(null);
    }
    if (innerTexts.addresVerify) {
      fetchAdress(
        `/cadde/${e.target.value}/${decryptData(cookies?.TC)}`,
        "GET"
      );
      setMah(e.target.value);
      return;
    }
  };

  const handleSelectStreet = (e) => {
    if (e.target.value === innerTexts.label_pick_street) {
      setVerifyBuildings(null);
    }
    if (innerTexts.addresVerify) {
      fetchAdress(`/bina/${e.target.value}/${decryptData(cookies?.TC)}`, "GET");
      setStreet(e.target.value);
      return;
    }
  };

  const handleSelectBuilding = (e) => {
    if (e.target.value === innerTexts.label_pick_building) {
      setVerifyFlat(null);
    }
    if (innerTexts.addresVerify) {
      fetchAdress(
        `/daire/${e.target.value}/${decryptData(cookies?.TC)}`,
        "GET"
      );
      setBuilding(e.target.value);
      return;
    }
  };

  const handleSelectFlat = (e) => {
    if (
      innerTexts.addresVerify &&
      e.target.value !== innerTexts.label_pick_flat
    ) {
      let birthDate = sessionStorage.getItem("bd");
      fetchAdress(
        `/adresdogrulama/${e.target.value}/${decryptData(cookies?.TC)}/${
          birthDate?.split("-")[2]
        }/${birthDate?.split("-")[1]}/${birthDate?.split("-")[0]}`,
        "GET",
        (data) => {
          setAddressSelectionError(data.Aciklama);
        }
      );
      setFlat(e.target.value);

      return;
    }
  };

  useEffect(() => {
    if (response) {
      if (!response.DurumKod || response.Message) {
        if (response.Message) {
          props.errorMessage(response.Message);
          props.setVerify(false);
        }
        props.errorMessage(response.Aciklama);
        props.setVerify(response.DurumKod ? true : false);
      }
      if (response.DurumKod) {
        const mahalle = verifyMah?.filter((x) => x.Kod == mah)[0]?.MahalleAdi;
        const cadde = verifyStreets?.filter((x) => x.Kod == street)[0]?.Ad;
        const bina = verifyBuildings?.filter((x) => x.BinaNo == building)[0]
          ?.DisKapiNo;
        const daire = verifyFlat?.filter((x) => x.AdresNo == flat)[0]?.IcKapiNo;
        const ilce = verifyRegions?.filter((x) => x.Kod == region)[0]?.Ad;
        const il = verifyCities?.filter((x) => x.Kod == city)[0]?.Ad;
        props.setAddress(
          `${mahalle} Mah. ${cadde} Cad. No: ${bina}, D: ${daire}. ${ilce}/ ${il}`
        );
        props.setMahalle(mahalle);
        props.setCadde(cadde);
        props.setBina(bina);
        props.setDaire(daire);
        props.errorMessage("");
        props.setPostalCode(postalCode);
        props.setVerify(response.DurumKod ? true : false);
        setVerified(true);
      }

      if (response.Data) {
        const { path, Data } = response;

        if (path === "il") {
          setVerifyCities(Data);
        } else if (path === `ilce/${city}`) {
          setVerifyRegions(Data);
        } else if (path === `mahalle/${region}`) {
          setVerifyMah(Data);
        } else if (path === `cadde/${mah}`) {
          setVerifyStreets(Data);
        } else if (path === `bina/${street}`) {
          setVerifyBuildings(Data);
        } else if (path === `daire/${building}`) {
          setVerifyFlat(Data);
        }
      }
    }
  }, [response]);
  useEffect(() => {
    fetchAdress(`/il/${decryptData(cookies?.TC)}`, "GET");
  }, []);
  return verified ? (
    <>
      <div className="col-md-12">
        <div
          style={{ marginTop: "10%" }}
          className="alert alert-success"
          role="alert"
        >
          Adres doğrulama {adress} olarak gerçekleştirilmiştir.
        </div>
      </div>
      {/* {innerTexts.id === "OSMANLIYATIRIM" && (
        <div className="col-md-6">
          <div
            style={{ display: "block" }}
            className="input-group  input-group-lg lg-12"
          >
            <div className="input-group-prepend">
              <label className="control-label">Posta Kodu</label>
            </div>
            <input
              onChange={(e) => {
                if (e.target.value.length <= 5) {
                  setPostalCode(e.target.value);
                  props.setPostalCode(e.target.value);
                }
              }}
              value={postalCode}
              aria-describedby="inputGroup-sizing-lg"
              maxLength="5"
              type="number"
              className="form-control"
              placeholder="Posta Kodu"
            />
          </div>
        </div>
      )} */}
    </>
  ) : (
    <>
      {addressSelectionError !== "" ? (
        <div className="col-md-12">
          <div
            style={{ marginTop: "10%" }}
            className="alert alert-danger"
            role="alert"
          >
            {addressSelectionError}
          </div>
        </div>
      ) : null}

      {!addressAlreadyVerified ? (
        <>
          {" "}
          {loading ? (
            <>
              <div className="KPSLoadingContainer">
                <ReactLoading
                  className="KPSLoading"
                  type={"spin"}
                  color={"#000"}
                  height={"10%"}
                  width={"5%"}
                />
                <div style={{ textAlign: "center" }}>
                  Kimlik Paylaşım Servisi'nden cevap bekleniyor...
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6">
                <div
                  style={{ display: "block", zIndex: 1 }}
                  className="input-group  input-group-lg lg-12"
                >
                  <div className="input-group-prepend">
                    <label className="control-label">
                      {innerTexts.label_region}
                    </label>
                  </div>
                  <select
                    onChange={handleSelectCity}
                    value={city}
                    className="form-control form-control-lg"
                  >
                    <option value={null}>{innerTexts.label_pick_city}</option>
                    {verifyCities !== null &&
                      verifyCities.map((x, i) => {
                        return (
                          <option key={i} value={x.Kod}>
                            {x.Ad}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  style={{ display: "block", zIndex: 1 }}
                  className="input-group  input-group-lg lg-12"
                >
                  <div className="input-group-prepend">
                    <label className="control-label">
                      {innerTexts.label_district}
                    </label>
                  </div>
                  <select
                    disabled={
                      !(verifyRegions !== null && verifyRegions.length > 0)
                    }
                    onChange={handleSelectRegion}
                    value={region}
                    className="form-control form-control-lg"
                  >
                    <option value={null}>{innerTexts.label_pick_region}</option>
                    {verifyRegions !== null &&
                      verifyRegions.map((x, i) => {
                        return (
                          <option key={i} value={x.Kod}>
                            {x.Ad}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  style={{ display: "block", zIndex: 1 }}
                  className="input-group  input-group-lg lg-12"
                >
                  <div className="input-group-prepend">
                    <label className="control-label">
                      {innerTexts.label_mah}
                    </label>
                  </div>
                  <select
                    disabled={!(verifyMah !== null && verifyMah.length > 0)}
                    onChange={handleSelectMah}
                    value={mah}
                    className="form-control form-control-lg"
                  >
                    <option value={null}>{innerTexts.label_pick_mah}</option>
                    {verifyMah !== null &&
                      verifyMah.map((x, i) => {
                        return (
                          <option key={i} value={x.Kod}>
                            {x.MahalleAdi === "KÖYÜN KENDİSİ" ||
                            x.MahalleAdi === "MERKEZ" ||
                            x.KoyAdi !== "MERKEZ"
                              ? x.KoyAdi === x.MahalleAdi
                                ? `${x.KoyAdi}`
                                : `${x.KoyAdi} - ${x.MahalleAdi}`
                              : x.MahalleAdi}{" "}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  style={{ display: "block", zIndex: 1 }}
                  className="input-group  input-group-lg lg-12"
                >
                  <div className="input-group-prepend">
                    <label className="control-label">
                      {innerTexts.label_street}
                    </label>
                  </div>
                  <select
                    disabled={
                      !(verifyStreets !== null && verifyStreets.length > 0)
                    }
                    onChange={handleSelectStreet}
                    value={street}
                    className="form-control form-control-lg"
                  >
                    <option value={null}>{innerTexts.label_pick_street}</option>
                    {verifyStreets !== null &&
                      verifyStreets.map((x, i) => {
                        return (
                          <option key={i} value={x.Kod}>
                            {x.Ad}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  style={{ display: "block", zIndex: 1 }}
                  className="input-group  input-group-lg lg-12"
                >
                  <div className="input-group-prepend">
                    <label className="control-label">
                      {innerTexts.label_building}
                    </label>
                  </div>
                  <select
                    disabled={
                      !(verifyBuildings !== null && verifyBuildings.length > 0)
                    }
                    onChange={handleSelectBuilding}
                    value={building}
                    className="form-control form-control-lg"
                  >
                    <option value={null}>
                      {innerTexts.label_pick_building}
                    </option>
                    {verifyBuildings !== null &&
                      verifyBuildings.map((x, i) => {
                        return (
                          <option key={i} value={x.BinaNo}>
                            {x.DisKapiNo}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  style={{ display: "block", zIndex: 1 }}
                  className="input-group  input-group-lg lg-12"
                >
                  <div className="input-group-prepend">
                    <label className="control-label">
                      {innerTexts.label_flat}
                    </label>
                  </div>
                  <select
                    disabled={!(verifyFlat !== null && verifyFlat.length > 0)}
                    onChange={handleSelectFlat}
                    value={flat}
                    className="form-control form-control-lg"
                  >
                    <option value={null}>{innerTexts.label_pick_flat}</option>
                    {verifyFlat !== null &&
                      verifyFlat.map((x, i) => {
                        return (
                          <option key={i} value={x.AdresNo} label={x.IcKapiNo}>
                            {x.IcKapiNo}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              {/* {innerTexts.id === "OSMANLIYATIRIM" && (
                <div className="col-md-6">
                  <div
                    style={{ display: "block" }}
                    className="input-group  input-group-lg lg-12"
                  >
                    <div className="input-group-prepend">
                      <label className="control-label">Posta Kodu</label>
                    </div>
                    <input
                      onChange={(e) => {
                        if (e.target.value.length <= 5) {
                          setPostalCode(e.target.value);
                        }
                      }}
                      value={postalCode}
                      aria-describedby="inputGroup-sizing-lg"
                      maxLength="5"
                      type="number"
                      className="form-control"
                      placeholder="Posta Kodu"
                    />
                  </div>
                </div>
              )} */}
            </>
          )}
        </>
      ) : (
        <>
          <div className="col-md-12">
            <div
              style={{ marginTop: "10%" }}
              className="alert alert-success"
              role="alert"
            >
              Adres doğrulama {adress} olarak gerçekleştirilmiştir.
            </div>
          </div>
          {/* {innerTexts.id === "OSMANLIYATIRIM" && (
            <div className="col-md-12">
              <div
                style={{ display: "block" }}
                className="input-group  input-group-lg lg-12"
              >
                <div className="input-group-prepend">
                  <label className="control-label">Posta Kodu</label>
                </div>
                <input
                  onChange={(e) => {
                    if (e.target.value.length <= 5) {
                      setPostalCode(e.target.value);
                      props.setPostalCode(e.target.value);
                    }
                  }}
                  value={postalCode}
                  aria-describedby="inputGroup-sizing-lg"
                  maxLength="5"
                  type="number"
                  className="form-control"
                  placeholder="Posta Kodu"
                />
              </div>
            </div>
          )} */}
        </>
      )}
    </>
  );
};
export default AdressVerify;
