import React, { useContext } from 'react'
import StepWizard from 'react-step-wizard'
import EpisodeOne from './step3/episode'
import EpisodeTwo from './step3/episodetwo'
import EpisodeThree from './step3/episodethree'
import EpisodeMasak from './step3/episodeMasak';
import EpisodeFour from './step3/episodeFour'
import EpisodeFive from "./step3/episodeFive";
import EpisodeSix from './step3/episodeSix';
import globalStates from '../../../../context/globalStates';
import StepIdentity from './stepIdentity';
import EpisodeJobs from './step3/episodeJobs'

const Step3 = (props)=>{
  const { innerTexts } = useContext(globalStates);
  // osmanli + isik + dinamik + hedef
  if((innerTexts.id === 'OSMANLIYATIRIM' || innerTexts.id === 'ISIKMENKUL' || innerTexts.id === 'DINAMIK' || innerTexts.id === 'HEDEF') && props.currentStep === 2) {
    return (
      <div className="panel-body">
        <div className="container">
          <StepWizard>
            <EpisodeOne handleWizard={props} />
            <EpisodeTwo handleWizard={props} />
            <EpisodeThree handleWizard={props} />
            { innerTexts.id !== 'HEDEF' && <EpisodeMasak currentStep={props.currentStep} handleWizard={props} /> }

            {/* episodeMasak.js */}
            {/* EpisodeFour Osmanlıdan kaldırıldığı için belirtilen sayfada ilerleme fonksiyonu değiştirildi, EpisodeFour yeniden açılırsa ilerleme fonksiyonunu da değiştirmelisin!  */}
            {innerTexts.id !== 'OSMANLIYATIRIM' && innerTexts.id !== 'HEDEF' && <EpisodeFour handleWizard={props} />}

            {innerTexts.portfoy && innerTexts.id !== 'HEDEF' && (
                <EpisodeFive handleWizard={props} />
            )}
            {innerTexts.branch && (
              <EpisodeSix handleWizard={props} />
            )}
          </StepWizard>
        </div>
      </div>
    );
  }

  // normal with all steps
  if (!innerTexts.customSteps && props.currentStep === 3) {
    return (
      <div className="panel-body">
        <div className="container">
          <StepWizard>
            <EpisodeOne handleWizard={props} />
            <EpisodeTwo handleWizard={props} />
            <EpisodeThree handleWizard={props} />
            {innerTexts.id !== 'ISTANBULPORTFOY' && <EpisodeMasak currentStep={props.currentStep} handleWizard={props} />}
            {innerTexts.id !== 'MEKSA' && innerTexts.id !== 'IKON' && <EpisodeFour handleWizard={props} />}
            {innerTexts.portfoy && innerTexts.id !== 'HEDEF' && (
                <EpisodeFive handleWizard={props} />
            )}
            {innerTexts.branch && innerTexts.id !== 'INFOYATIRIM' && (
              <EpisodeSix handleWizard={props} />
            )}
          </StepWizard>
        </div>
      </div>
    );
  } else {
    return (<div></div>)
  }

}
export default Step3
