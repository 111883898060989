import React from 'react'
import { useCookies } from 'react-cookie';


export const URL = process.env.NODE_ENV === "development" ?
    "http://localhost:3003/webapi/Default.aspx?" :
    "/webapi/Default.aspx?"

const useRequest = () => {
    const [response, setResponse] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [cookie] = useCookies()
    let isSubscribed = true
    const startFetch = (params, method, body, contentType, callback) => {
        setLoading(true)
        fetch(URL + params, {
            method: method,
            headers: {
                'Content-Type': contentType || 'application/json; charset=UTF-8',
                "Authorization": cookie.token
            },
            body
        }).then(res => {
            if (isSubscribed) {
                if (res.ok) {
                    return res.json()
                }
            }
        }).then((data) => {
            if (isSubscribed) {
                if (data.errNo === -4) {
                    window.location.replace("/")
                }

                // special case for under age 18
                if(data.errNo === -30) {
                    callback(data)
                }

                // special case for ohter different errors such as repeated sms request or etc
                if(data.errNo === -50 || data.errNo === -51 || data.errNo === -52 || data.errNo === -53 || data.errNo === -54) {
                    callback(data)
                }

                // special case for iban verify when iban is wrong
                if(data.errNo === -16) {
                    callback(data)
                }

                // special case for person family and other information verify
                if(data.errNo === -62 || data.errNo === -61 || data.errNo === -60 || data.errNo === -59) {
                    callback(data)
                }

                setResponse(data)
                setLoading(false)

                if(data.errNo === 0 || data.Step) {
                    callback(data)
                }
            }
        }).catch((error) => {
            if (isSubscribed) {
                setError(error)
                setLoading(false)

            }
        })
        return () => {
            isSubscribed = false
        }
    }
    const fetchAdress = (params, method, callback) => {
        setLoading(true)
        fetch(`${"/adres"}${params}`, {
            method: method,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                // "Authorization": cookie.token
            }
        }).then(res => {
            if (isSubscribed) {
                if (res.ok) {
                    return res.json()
                }
            }
        }).then((data) => {
            if (isSubscribed) {

                if (data.errNo === -4) {
                    window.location.replace("/")
                }

                // special case for address verify when address is wrong
                if (data.DurumKod == false) {
                    if(callback) {
                        callback(data)
                    }
                }
            
                const path = params.substring(
                    params.startsWith("/"),
                    params.lastIndexOf("/")
                );
                setResponse({ ...data, path: path })
                setLoading(false)
                if (callback) {
                    callback(data)
                }
            }
        }).catch((error) => {
            if (isSubscribed) {
                setError(error)
                setLoading(false)
            }
        })
        return () => isSubscribed = false
    }

    const ImageUpload = (params, file, callback) => {
        setLoading(true)
        fetch(URL + params, {
            method: "POST",
            headers: {
                "Content-Type": file.type,
                "Authorization": cookie.token
            },
            body: file
        }).then(res => {
            if (isSubscribed) {
                if (res.ok) {
                    return res.json()
                } else {
                    alert("Dosya yüklenemedi!")
                }
            }
        }).then((data) => {
            if (isSubscribed) {
                if (data.errNo === -4) {
                    window.location.replace("/")
                }
                if (data.errNo === -5) {
                    window.location.replace("/")
                }
                setResponse(data)
                setLoading(false)
                callback(data)
            }
        }).catch((error) => {
            if (isSubscribed) {
                setError(error)
                setLoading(false)

            }
        })
        return () => isSubscribed = false
    }




    return { response, error, loading, startFetch, fetchAdress, ImageUpload, URL };
}

export default useRequest
