import React, { Fragment, useContext, useEffect, useState } from "react";
import globalStates from "../../../../../context/globalStates";
import useRequest from "../../../../../hooks/useRequest";
import {useCookies} from "react-cookie";
import { decryptData } from '../../../../../helper/helper';

const EpisodeSix = (props) => {
    const [pickSupport, setPickSupport] = useState("");
    const [supportList, setSupportList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [winner, setWinner] = useState([])
    const [pickWinner, setPickWinner] = useState("")
    const [pickBranch, setPickBranch] = useState("");
    const { startFetch, response, loading } = useRequest();
    const [danger, setDanger] = useState({ valid: false, message: "" });
    const { innerTexts } = useContext(globalStates);
    const [cookie] = useCookies(['TC']);

    const urlBranch = sessionStorage.getItem("branch");
    const urlSupport = sessionStorage.getItem("support");

    useEffect(() => {
        if(innerTexts.id === 'MEKSA' || innerTexts.id === 'ALNUSYATIRIM' || innerTexts.id === 'INVESTAZ') {
            if (urlBranch && urlSupport) {
                setPickBranch(urlBranch);
                setPickSupport(urlSupport);
            }
        }

    }, [supportList.length, branchList.length]);


    // this is for ALNUSYATIRIM to skip this step if branch and support is selected and send the request
    useEffect(() => {
        if(innerTexts.id === 'ALNUSYATIRIM' && props.currentStep === 6) {
            if (urlSupport && urlBranch) {
                var params = "";
                var model = {
                    islem: "yatirimci_bilgileri_diger",
                    sube: urlBranch,
                    temsilci: urlSupport
                };
                for (var I in model) {
                    params += "" + I + "=" + model[I] + "&";
                }
                setDanger({ valid: false, message: "" });
                startFetch(params, "POST", null, null, (data) => {
                    if(data.errNo === 0) {
                        props.nextStep();
                    }
                });
            }
        }
    }, [urlSupport, urlBranch, props.currentStep]);

    useEffect(() => {
        if (response) {
            if (response.errNo === 0) {
                if (response.bilgiler) {
                    setPickBranch(response.bilgiler.sube);
                    return;
                }
                if (response.subeler) {
                    var data = response.subeler;
                    setBranchList(data);
                } else if (response.temsilciler) {
                    var data = response.temsilciler;
                    setSupportList(data);
                } else if (response.kazandiran) {
                    var data = response.kazandiran;
                    setWinner(data);
                } else {
                    props.handleWizard.nextStep();
                }
            }
        }
    }, [response]);

    const handlePickBranch = (value) => {

        var params = ""
        var model = {}
        model.islem = 'temsilciler'
        model.sube_no = value

        for (var I in model) {
            params += '' + I + '=' + model[I] + '&'
        }

        startFetch(params, "GET", null, null, (data) => {
            if(data.errNo === 0) {
                setSupportList(data.temsilciler)
            }
        })

    }
    useEffect(() => {
        startFetch("islem=subeler", "GET");
        startFetch("islem=temsilciler", "GET");
    }, []);
    useEffect(() => {
        startFetch("islem=yatirimci_bilgileri_getir&step=3", "GET");
    }, []);
    const saveForm = () => {
        
        var errorElement = window.jQuery("html");
        var scrollPosition = errorElement.offset().top - 25;
        window.jQuery('html, body').animate({ scrollTop: scrollPosition }, 500);

        if(!urlBranch && !urlSupport) {
            if (innerTexts.pickSupport) {
                if (pickSupport === "") {
                    return setDanger({
                        message: innerTexts.error_messages_step_one_episode,
                        valid: true,
                    });
                }
            }
            if (innerTexts.branch) {
                if (pickBranch === "") {
                    return setDanger({
                        message: innerTexts.error_messages_step_one_episode,
                        valid: true,
                    });
                }
            }
        }

        if (innerTexts.winner) {
            if (pickWinner === "") {
                return setDanger({
                    message: innerTexts.error_messages_step_one_episode,
                    valid: true,
                });
            }
        } 

        var params = "";
        var model = {
            islem: "yatirimci_bilgileri_diger",
            sube: pickBranch ? pickBranch : urlBranch,

        };

        if (innerTexts.pickSupport) {
            model = {
                ...model,
                temsilci: pickSupport ? pickSupport : urlSupport
            }
        }
        if (innerTexts.winner) {
            model = {
                ...model,
                kazandiran: pickWinner
            }
        }
        for (var I in model) {
            params += "" + I + "=" + model[I] + "&";
        }
        setDanger({ valid: false, message: "" });
        startFetch(params, "POST");
    };
    return (
        <Fragment>
            <div style={{ textAlign: "center" }}>
                <h2> </h2>
                <div style={{ textAlign: "left" }} className="row">
                    <div className="col-md-12">
                        <div style={{ cursor: "pointer", width: "100%" }} className="card">
                            <div style={{ cursor: "pointer" }} className="card-body">
                                <div
                                    style={{ display: "block", zIndex: 2 }}
                                    className="input-group  input-group-lg lg-12"
                                >
                                    <div className="input-group-prepend">
                                        <label className="control-label">
                                            {" "}
                                            {innerTexts.label_pickup_branch}
                                        </label>
                                    </div>
                                    <div>
                                        <select
                                            className="form-control"
                                            onChange={(e) => {
                                                setPickBranch(e.target.value);
                                                handlePickBranch(e.target.value);
                                            }}
                                            value={(innerTexts.id === 'MEKSA' || innerTexts.id === 'ALNUSYATIRIM' || innerTexts.id === 'INVESTAZ') && urlBranch ? urlBranch : pickBranch}
                                        >
                                            <option value="">Seçiniz</option>

                                            {branchList &&
                                                branchList.map((item, key) => {

                                                    if((innerTexts.id === 'MEKSA' || innerTexts.id === 'ALNUSYATIRIM' || innerTexts.id === 'INVESTAZ') && urlBranch) {
                                                        if(item.key === urlBranch) {
                                                            return (
                                                                <option
                                                                    key={key}
                                                                    value={item.key}
                                                                >
                                                                    {item.value}
                                                                </option>
                                                            )
                                                        }
                                                    } else {
                                                        return (
                                                            <option
                                                                key={key}
                                                                value={item.key}
                                                            >
                                                                {item.value}
                                                            </option>
                                                        )
                                                    }
                                                    })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    {innerTexts.pickSupport && (
                                    <div
                                        style={{ display: "block", zIndex: 2 }}
                                        className="input-group  input-group-lg lg-12"
                                    >
                                        <div className="input-group-prepend">
                                            <label className="control-label">
                                                {" "}
                                                {innerTexts.label_pickup_support}
                                            </label>
                                        </div>
                                            <div>
                                            <select
                                                className="form-control"
                                                onChange={(e) => {
                                                    setPickSupport(e.target.value);
                                                }}
                                                value={(innerTexts.id === 'MEKSA' || innerTexts.id === 'ALNUSYATIRIM' ||  innerTexts.id === 'INVESTAZ') && urlSupport ? urlSupport : pickSupport}
                                            >
                                                <option value="">Seçiniz</option>

                                                {supportList &&
                                                    supportList.map((item, key) => {

                                                        if((innerTexts.id === 'MEKSA' || innerTexts.id === 'ALNUSYATIRIM' ||  innerTexts.id === 'INVESTAZ') && urlSupport) {
                                                            if(item.key === urlSupport) {
                                                                return (
                                                                    <option
                                                                        key={key}
                                                                        value={item.key}
                                                                    >
                                                                        {item.value}
                                                                    </option>
                                                                )
                                                            }
                                                        } else {
                                                            return (
                                                                <option
                                                                    key={key}
                                                                    value={item.key}
                                                                >
                                                                    {item.value}
                                                                </option>
                                                            )
                                                        }
                                                    })}
                                            </select>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {
                                    innerTexts.winner && (
                                        <div className="col-md-12">
                                            <div
                                                style={{ display: "block", zIndex: 2 }}
                                                className="input-group  input-group-lg lg-12"
                                            >
                                                <div className="input-group-prepend">
                                                    <label className="control-label">
                                                        {" "}
                                                        {innerTexts.label_pickup_winner}

                                                    </label>
                                                </div>
                                                {innerTexts.winner && (<div>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            setPickWinner(e.target.value);
                                                        }}
                                                        value={pickWinner}
                                                    >
                                                        <option value="">Seçiniz</option>

                                                        {winner &&
                                                            winner.map((item, key) => (
                                                                <option key={key} value={item.id}>{item.aciklama}</option>
                                                            ))}
                                                    </select>
                                                </div>)}
                                            </div>
                                        </div>
                                    )
                                }

                                <div className="col-md-12">
                                    {danger.valid ? (
                                        <div
                                            style={{ marginTop: 10 }}
                                            className="alert alert-danger"
                                            role="alert"
                                        >
                                            {danger.message}
                                        </div>
                                    ) : (
                                        void 0
                                    )}
                                </div>
                                <div style={{ marginTop: 20 }} className="col-md-6">
                                    <button
                                        onClick={() => {
                                            props.previousStep();
                                        }}
                                        className="btn  btn-secondr btn-lg btn-block"
                                        disabled={loading}
                                        style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                                    >
                                        {innerTexts.btn_text_back}
                                    </button>
                                </div>

                                <div style={{ marginTop: 20 }} className="col-md-6">
                                    <button
                                        onClick={saveForm}
                                        className="btn  btn-primary btn-lg btn-block"
                                        disabled={loading}
                                        style={{ filter: loading ? "brightness(120%)" : "", cursor: loading ? "no-drop" : "pointer" }}
                                    >
                                        {loading ? "Yükleniyor..." : innerTexts.btn_text_forward}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default EpisodeSix;
