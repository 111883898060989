
import React, { useContext } from 'react'
import globalStates from '../context/globalStates'
const FloatingBar = () => {
	const { innerTexts } = useContext(globalStates)

	return (
    <div id="floatingBar" style={{display: innerTexts.companyFloatingBarText ? "block" : "none", position:"sticky", bottom:0, textAlign:"center", zIndex:"999", backgroundColor:"white", borderTop:"1px solid black", paddingTop:"5px"}}>
      <p>{innerTexts.companyFloatingBarText}</p>
    </div>
  );
}
export default FloatingBar